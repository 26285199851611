/* Full-screen layout */
.room { 
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
  }
#remote-users-container .user {
    max-width: 100%;
}
  /* Video container */
  .user-list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
  
  /* Local user video */
  .custom-local-user { 
    aspect-ratio: 16/9; 
    border-radius: 10px;
    border: 2px solid white;
    overflow: hidden;
  }
  
  /* Remote users layout */
  #remote-users-container {
    flex: 1 1;
    width: 100%;
    max-width: 45%;
    aspect-ratio: 16 / 9;
    position: relative;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;
}
  
  /* Remote user video */
  .user {
    width: 100%;
    max-width: 45%;
    aspect-ratio: 16/9;
    position: relative;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;
  }
  
  /* User name label */
  .user-name {
    position: absolute;
    bottom: 5px;
    left: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Controls (Bottom Bar) */
  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }
  
  .left-control {
    display: flex;
    gap: 10px;
  }
  
  .btn {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .btn-phone {
    background-color: red;
    padding: 12px 18px;
    border-radius: 50%;
  }
  
  .btn-phone-active {
    background-color: green;
  }
  